export enum TemplateType {
  NewTicket = 'NewTicket',
  Reinitialise = 'Reinitialise',
  FormRequest = 'FormRequest',
  PaymentRequest = 'PaymentRequest',
  PaymentPaid = 'PaymentPaid',
  PaymentRefunded = 'PaymentRefunded',
  PreAuthorisationSuccess = 'PreAuthorisationSuccess',
  Custom = 'Custom',
  Campaign = 'Campaign',
  CampaignWithPayment = 'CampaignWithPayment',
  Notification = 'Notification',
  Welcome = 'Welcome',
  Standard = 'Standard',
  ProductRequestUpdate = 'ProductRequestUpdate',
  ReminderAppointment = 'ReminderAppointment',
  ReminderPayment = 'ReminderPayment',
  ReminderVaccination = 'ReminderVaccination',
  ReminderParasiticide = 'ReminderParasiticide',
  ReminderPreOperation = 'ReminderPreOperation',
  ReminderPostOperation = 'ReminderPostOperation',
  ReminderHealthCarePlan = 'ReminderHealthCarePlan',
  ReminderSnoozeResponse = "ReminderSnoozeResponse",
}
