import { Component, Input } from '@angular/core';

@Component({
  selector: 'message-button',
  templateUrl: './message-button.component.html',
  styleUrls: ['./message-button.component.scss']
})
export class MessageButtonComponent {
  @Input() value: string = '';
  @Input() icon: string = '';
}
