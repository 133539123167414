import { Pipe, PipeTransform } from '@angular/core';
import { Template } from '../models/Template';
import { isSystemTemplate } from '../helpers/is-system-template';

@Pipe({name: 'isSystemTemplate'})
export class IsSystemTemplatePipe implements PipeTransform {
  transform(template: Template): boolean {
    return isSystemTemplate(template);
  }
}
