export enum MergeFieldContent {
  PracticeName = 'PracticeName',
  ClientName = 'ClientName',
  ClientPostcode = 'ClientPostcode',
  ClientBalance = 'ClientBalance',
  ClientTitle = 'ClientTitle',
  ClientFirstName = 'ClientFirstName',
  ClientLastName = 'ClientLastName',
  PatientName = 'PatientName',
  PaymentAmount = 'PaymentAmount',
  PaymentType = 'PaymentType',
  PaymentExpiry = 'PaymentExpiry',
  PaymentId = 'PaymentId',
  PaymentLink = 'PaymentLink',
  PaymentSentAt = 'PaymentSentAt',
  PaymentMessage = 'PaymentMessage',
  NotificationText = 'NotificationText',
  NotificationLink = 'NotificationLink',
  ProductRequestItems = 'ProductRequestItems',
  ProductRequestUpdate = 'ProductRequestUpdate',
  AppUrl = 'AppUrl',
  FormName = 'FormName',
  Custom = 'Custom',
}
