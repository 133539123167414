import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Template } from '../../models/Template';
import { PlaceholderService } from './placeholder.service';
import { Practice } from '../../models/Practice';
import { Client } from '../../models/Client';
import { Patient } from '../../models/Patient';
import { TemplateMergeField } from '../../models/TemplateMergeField';
import { MergeFieldContent } from '../../enums/merge-field-content';

@Injectable({
  providedIn: 'root'
})
export class ConversationService {
  public initialMessage = new UntypedFormControl('');
  public linkButton = new UntypedFormControl('');
  public quickReply = new UntypedFormControl('');
  public quickReply2 = new UntypedFormControl('');
  public practice?: Practice | null;
  public patient?: Patient | null;
  client?: Client;

  constructor(private placeholderService: PlaceholderService) {}

  private getMergeFieldReplacement(
    mergeField: TemplateMergeField,
    placeholders: { placeholderId: string; replacement: string }[]
  ): string {
    switch (mergeField.content) {
      case MergeFieldContent.PracticeName:
        return (
          this.practice?.name ||
          placeholders.find(
            (p) => p.placeholderId === mergeField.id.toString()
          )?.replacement ||
          mergeField.placeholder
        );

      case MergeFieldContent.ClientName:
        return (
          placeholders.find(
            (p) => p.placeholderId === mergeField.id.toString()
          )?.replacement || mergeField.placeholder
        );
      case MergeFieldContent.ClientTitle:
        return (
          placeholders.find(
            (p) => p.placeholderId === mergeField.id.toString()
          )?.replacement || mergeField.placeholder
        );
  
      case MergeFieldContent.ClientFirstName:
        return (
          placeholders.find(
            (p) => p.placeholderId === mergeField.id.toString()
          )?.replacement || mergeField.placeholder
        );
  
      case MergeFieldContent.ClientLastName:
        return (
          placeholders.find(
            (p) => p.placeholderId === mergeField.id.toString()
          )?.replacement || mergeField.placeholder
        );
      case MergeFieldContent.PatientName:
        return (
          this.patient?.name || placeholders.find(
            (p) => p.placeholderId === mergeField.id.toString()
          )?.replacement || mergeField.placeholder
        );
      case MergeFieldContent.ClientPostcode:
        return (
          this.client?.postcode ||
          placeholders.find(
            (p) => p.placeholderId === mergeField.id.toString()
          )?.replacement ||
          mergeField.placeholder
        );

      case MergeFieldContent.Custom:
        return (
          placeholders.find(
            (p) => p.placeholderId === mergeField.id.toString()
          )?.replacement ||
          mergeField.placeholder
        );
    }

    return mergeField.placeholder;
  }

  getTemplateText(
    template: Template,
    placeholders: { placeholderId: string; replacement: string }[]
  ): string {
    let templateText = template.body;

    template.mergeFields.forEach((mergeField) => {
      templateText = templateText.replace(
        mergeField.placeholder,
        this.getMergeFieldReplacement(mergeField, placeholders)
      );
    });

    return templateText;
  }

  updatePreviewConversation(
    template: Template,
    placeholders: { placeholderId: string; replacement: string }[]
  ) {
    const text = this.placeholderService.replacePlaceholders(
      template.body ?? '',
      template.mergeFields,
      placeholders,
      this.practice,
      this.patient,
      this.client
    );
    this.initialMessage.setValue(text);

    const linkButton = this.placeholderService.replacePlaceholders(
      template.paymentButtonText ?? '',
      template.mergeFields,
      placeholders,
      this.practice,
      this.patient,
      this.client
    );
    this.linkButton.setValue(linkButton);

    const quickReply = this.placeholderService.replacePlaceholders(
      template.quickReply ?? '',
      template.mergeFields,
      placeholders,
      this.practice,
      this.patient,
      this.client
    );
    this.quickReply.setValue(quickReply);

    const quickReply2 = this.placeholderService.replacePlaceholders(
      template.quickReply2 ?? '',
      template.mergeFields,
      placeholders,
      this.practice,
      this.patient,
      this.client
    );
    this.quickReply2.setValue(quickReply2);
  }
}
