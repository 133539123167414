import {WhatsappTemplateDto} from '../interfaces/dto/whatsapp-template.dto';
import {Template} from '../models/Template';
import {Injectable} from '@angular/core';
import { TemplateStatus } from '../enums/template-status';
import { TemplateConsentRequiredOption } from '../enums/template-consent-required-option';
import { TemplateArchiveStatus } from '../enums/template-archive-status';
import {MediaAdapter} from "./media.adapter";

@Injectable({
  providedIn: 'root'
})
export class WhatsappTemplateAdapter {
  constructor(private mediaAdapter: MediaAdapter) {
  }
    run(dto: WhatsappTemplateDto): Template {
      return {
        active: dto.active ? TemplateStatus.ACTIVE : TemplateStatus.INACTIVE,
        body: dto.body,
        externalId: dto.externalId,
        externalName: dto.externalName,
        language: dto.language,
        id: dto.id,
        name: dto.name,
        practiceId: dto.practiceId,
        paymentButtonText: dto.paymentButtonText,
        paymentButton: dto.paymentButton,
        button: dto.button,
        buttonLink: dto.buttonLink,
        addQuickReplies: dto.addQuickReplies,
        quickReply: dto.quickReply,
        quickReply2: dto.quickReply2,
        status: dto.status,
        category: dto.category || null,
        rejectedReason: dto.rejectedReason || null,
        type: dto.type,
        attachMedia: dto.attachMedia,
        mediaType: dto.mediaType,
        defaultMedia: dto.defaultMedia ? this.mediaAdapter.run(dto.defaultMedia) : undefined,
        mergeFields: dto.mergeFields,
        consentRequired: dto.externalId ? TemplateConsentRequiredOption.NOT_REQUIRED : TemplateConsentRequiredOption.REQUIRED,
        archived: dto.deletedAt ? TemplateArchiveStatus.ARCHIVED : TemplateArchiveStatus.NOT_ARCHIVED,
        edits: dto.edits ? dto.edits.map((edit) => {
          return {
            ...edit,
            createdAt: new Date(edit.createdAt)
          };
        }).sort((a, b) => {
          return b.createdAt.getTime() - a.createdAt.getTime();
        }) : [],
      };
    }
}
